/**
 * External Dependencies
 */

 import 'jquery';
 import "@lottiefiles/lottie-player";
 import Swiper from 'swiper';
 var $ = require('jquery');

import Splitting from 'splitting';
import ScrollOut from 'scroll-out';

 $(document).ready(function() {

  var swiper = new Swiper('.swiper-container', {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
      pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
      },
  });

  // Scroll to
  $("a[href^='#']").on('click', function (e) {
    e.preventDefault();
    var hash = this.hash;
    $('html, body').animate({
      scrollTop: ($(hash).offset().top - 161)
    }, 500);
  });

  // If is in viewport function
  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  //Pop-up
  var $myDiv = $('#contact');

  if ( $myDiv.length){
    $(window).on('resize scroll', function() {
      if($(window).width() >= 1024) {
        if ($('#contact').isInViewport()) {
          $('.popup').fadeOut();
        } else {
          if (!$(".popup").hasClass("closed")) {
            $('.popup').fadeIn();
          }
        }
      }
    });

    $(".close-popup").on('click', function (e) {
      $('.popup').fadeOut();
      $('.popup').addClass('closed');
    });
  }

  // SCROLLED NAV
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  //SPLITTING
  Splitting();

  //SCROLLOUT
  ScrollOut({
   targets: '[data-splitting]',
  });

  // HAMBURGER
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close-nav').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $(".button, .anchor").on('click', function(event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 1000, function() {

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
      return false;
    } // End if
  });

  $(".open-modal").on('click', function(){
    $('body').toggleClass('overflow-hidden');
    var id = $(this).attr('data-id');
    // var vacancyTitle = $(this).parents('li').find('.vacancy__title').text();
    // $(".gform_hidden input.gform_hidden").val(vacancyTitle);
    $(`section[data-id=${id}], div[data-id=${id}]`).toggleClass('invisible opacity-0');
  });

  $(".close-modal").on('click', function(){
    $('body').toggleClass('overflow-hidden');
    var id = $(this).parents('.modal-window').attr('data-id');
    // $(".gform_hidden input.gform_hidden").val('');
    $(`section[data-id=${id}], div[data-id=${id}]`).toggleClass('invisible opacity-0');
  });

  // ACCORDION
  (function($){
    function close_accordion_section() {
      $('.faq__accordion .faq__title a').removeClass('open');
      $('.faq__accordion .faq__content').slideUp(300).removeClass('active');
      $('.faq__accordion').removeClass('active');
    }
  
    $('.faq__title a').click(function(e) {
      e.preventDefault();
  
      var currentAttrValue = $(this).data('target');
  
      if($(this).is('.open')) {
        close_accordion_section();
      } else {
        close_accordion_section();
        $(this).addClass('open');
        $(this).parents('.faq__accordion').addClass('active');
        $(this).addClass('open');
        $(currentAttrValue).slideDown(300).addClass('active');
      }
    });
  })(jQuery);  


  // TYPE
  var TxtType = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
  };

  TxtType.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

    var that = this;
    var delta = 100 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function() {
    that.tick();
    }, delta);
  };

  window.onload = function() {
    var elements = document.getElementsByClassName('typewrite');
    for (var i=0; i<elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
  };

  // NAV SELECTOR
  var lastId,
      topMenu = $('.nav'),
      topMenuHeight = topMenu.outerHeight(),
      menuItems = topMenu.find('.menu-primair-container a:not([rel="nofollow"])'),
      scrollItems = menuItems.map(function(){
        var item = $($(this).attr('href'));
        if (item.length) { return item; }
      });

      menuItems.click(function(e){
        var href = $(this).attr('href'),
            offsetTop = href === '#' ? 0 : $(href).offset().top-topMenuHeight+1;
        $('html, body').stop().animate({
            scrollTop: offsetTop,
        }, 500);
        e.preventDefault();
      });

  // Bind to scroll
  $(window).scroll(function(){
      // Get container scroll position
      var fromTop = $(this).scrollTop()+topMenuHeight;

      // Get id of current scroll item
      var cur = scrollItems.map(function(){
        if ($(this).offset().top < fromTop)
          return this;
      });
      // Get the id of the current element
      cur = cur[cur.length-1];
      var id = cur && cur.length ? cur[0].id : '';

      if (lastId !== id) {
          lastId = id;
          // Set/remove active class
          menuItems
            .parent().removeClass('active')
            /* eslint-disable */
            .end().filter("[href='#"+id+"']").parent().addClass('active');
            /* eslint-enable */
      }
  });
});
